import Vue from 'vue';

export default Vue.extend({
  watch: {
    '$v.$invalid': {
      immediate: true,
      handler(newValue: boolean): void {
        this.$emit('valid', !newValue);
      },
    },
  },
});
